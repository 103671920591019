<template>
  	<el-dialog title="Antet/Subsol" width="75%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='false'>
		<el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='selectedObject' @submit.prevent="save" v-loading="loadingVisible" >
			<el-row :gutter="15" >      
				<el-col :md='8'> 
					<el-form-item label='Nume' prop="Nume" >
						<el-input  class='full-width' v-model='selectedObject.Nume' />
					</el-form-item>
				</el-col> 
				<el-col :md="8">
					<el-form-item label='Tip Template' prop="TipTemplate" >
						<el-select class='full-width' v-model="selectedObject.TipTemplate" placeholder="Select">
							<el-option v-for="item in Info.tipuri_formulare" :key="item.key" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
				

			<!-- <el-row class="formular-draggable">
				<el-card class="card-principal">	
					<div slot="header" class="clearfix">
						<span>Ordine rezolvare</span>
					</div>	
					
					<el-col :span="10">
						<el-card ref="cardTabelLista" class="card-tabel-lista">	
							<div slot="header" class="clearfix">
								<span>Functii</span>
							</div>					
							<el-table size="medium" :data="Info.functii_angajati" ref="multipleTable" @selection-change="handleSelectionChange" @row-click="rowClicked">
								<el-table-column type="selection" width="55" ></el-table-column>
								<el-table-column label='Select toate' prop="Nume" ></el-table-column>								
							</el-table>	
						</el-card>			
					</el-col>

					<el-col :span="3" :offset="1" :style="tabelListaHeight">
						<el-button class="buton-adauga-tabel" type="primary" plain @click="adaugaInLista" > ADAUGA <i class="el-icon-right" ></i>  </el-button>
					</el-col>

					<el-col :span="10" >
						<el-card :style="tabelListaHeight">
							<div slot="header" class="clearfix">
								<span>Pasaport de semnaturi</span>
							</div>
							<el-table-draggable :animate="400">
								<el-table size="medium" :data="selectedObject.OrdineRezolvare">
									<el-table-column type="index" width="50px" label="#"></el-table-column>
									<el-table-column label="Nume" prop="Nume"></el-table-column>
									<el-table-column fixed="right" label="Actiuni" width="100px" >
										<template slot-scope="scope" >
											<el-tooltip content="Sterge" >
												<el-button size="mini" type="danger" icon="el-icon-delete" circle @click="delete_item(scope.$index, selectedObject.OrdineRezolvare)"  />
											</el-tooltip>
										</template>
									</el-table-column>
								</el-table>
							</el-table-draggable>
						</el-card>
					</el-col>	
					
				</el-card>	
			</el-row>			 -->

			<el-row :gutter="20">
				<el-col>
					<el-form-item label='Continut' prop="Continut">
						<summernote
						ref="summernote" 
						class="form-control"
						height="500"
						:arata_variabile="true"
						name="formular_header_content"
						:model.sync="selectedObject.Continut"
						@addVariable="showAddVariable"></summernote>
					</el-form-item>
				</el-col>            
			</el-row>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="resetForm('selectedObject')" > Renunta  </el-button>
			<el-button type="primary" @click="save('selectedObject')" > Salveaza </el-button>
		</span>

		<addVariable ref="addVariable" @insertedVar="getVariabilaInserata" @existingVarsReady="getExistingVars"/>
		<bpImageUpload/>

  	</el-dialog>
</template>

<script>
// Advanced Use - Hook into Quill's API for Custom Functionality
import { VueEditor, Quill } from "vue2-editor"
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage'
import FormularEditor from './FormularEditor'
import Summernote from './Summernote'
import AddVariable from './AddVariable'
import BpImageUpload from './BpImageUpload'
import draggable from 'vuedraggable'
import ElTableDraggable from 'element-ui-el-table-draggable';
import $ from 'jquery'

export default {
    Nume: "Template_header_dialog",
    extends: BasePage,    
    components: {
      formularEditor: FormularEditor,
      summernote: Summernote,
      addVariable: AddVariable,
      bpImageUpload: BpImageUpload,
	  draggable,
	  ElTableDraggable
    },
    data () {
      	return {
			showPopup: false,
			mode: 'add',
			selectedObject: { Nume: '', NumarZileSolutionare:'', Continut: '', OrdineRezolvare:[], GrupUtilizatoriAsociati:[], TipTemplate: ''},                 
			Info:{ tipuri_formulare: [], functii_angajati:[], grupuri_utilizatori:[]},
			rules: {
				Nume:     					[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'  }],
				Continut: 					[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }],
			},
			multipleSelection: [],
			tabelListaHeight:{},
			existingVars:[]
      }
    },
    methods: {
        show_me: async function( id ) {
          this.selectedObject = { Nume: '', NumarZileSolutionare:'', Continut: '', OrdineRezolvare:[], GrupUtilizatoriAsociati:[] }
            this.showPopup    = true;
            if( id == null )
            {
                this.mode = "add";
            }
            else
            {
                this.mode			= "edit";
                var result 			= await this.post("template_header_footer/get_info_item_dialog", { id: id } );
                this.selectedObject	= result.Item;			
                this.$forceUpdate()
            }
            this.$nextTick(() => {
              	this.$refs.summernote.refresh()
				setTimeout(() => this.matchHeight(), 50);
            })			
			
        },
        async get_info(){
            var response 					= await this.post("template_header_footer/get_info_for_dialog" );
			this.Info.functii_angajati		= response.functii_angajati
			this.Info.grupuri_utilizatori	= response.grupuri_utilizatori
			this.Info.tipuri_formulare      = [{key: 0, label: 'Antet', value: 'Header'}, {key: 1, label: 'Subsol', value: 'Footer'}]
        },
        async save(formNume) {
			this.$refs[formNume].validate( async(valid) => {
				if (valid)
				{
					await this.post("template_header_footer/save", { mode: this.mode, object: this.selectedObject, vars: this.existingVars } );
					this.resetForm(formNume)
					this.$emit("save");
				} else {console.log('eroare validare formular!');}
			});                
		},
		resetForm(formNume) {
			this.$refs[formNume].resetFields()
			this.showPopup = false
		},
        showAddVariable() {
          	this.$refs.addVariable.show()
        },
		async delete_item( index, rows ){           
			this.$confirm('Esti sigur ?', 'Warning', {
				cancelButtonText: 'Renunta',
				type: 'warning'
				}).then(() => {
					rows.splice(index, 1);					
				}).catch(() => {
				this.$message({
					type: 'info',
					message: 'Stergere anulata'
				});          
			});
        },
		adaugaInLista(){
			this.multipleSelection.forEach(m=>this.selectedObject.OrdineRezolvare.push(m))
			this.selectedObject.OrdineRezolvare = this.eliminaIntrariDublate(this.selectedObject.OrdineRezolvare)			
		},
		eliminaIntrariDublate(arrayOfObjects){
			var uniqueArrayOfObjects = arrayOfObjects.filter((thing, index, self) =>
				index === self.findIndex((t) => (
						t.Id == thing.Id
					))
				)

			return uniqueArrayOfObjects
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		rowClicked(row){
			console.log('row clicked',row);
			// this.multipleSelection.push(row)
			this.$refs.multipleTable.toggleRowSelection(row);
		},
		matchHeight() {				
			// var height = this.$refs.cardTabelLista.$el.clientHeight
            // this.$set(this.tabelListaHeight, 'height', height+'px'); 
        },
		getVariabilaInserata(item){
			this.existingVars.push(item)
		},
		getExistingVars(items){			
			this.existingVars = items
		}
    },
    mounted: function() {
      this.get_info();
    }
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

.formular-draggable{
	margin-bottom: 20px;
	.list {
        background-color: #EBEEF5;
        font-size: 14px;
        .header.el-row {
            background-color: #FFF;
            color: #909399;
            font-weight: 500;            
        }
        .el-row {
            background-color: #FFF;
            transition: background-color .25s ease;
            border-bottom: 1px solid #EBEEF5;
            color: black;
            height: 40px;
            .el-col {
                border-bottom: 1px solid #EBEEF5;
                padding-top: 5px;
                padding-bottom: 5px;
                height: 100%;
                padding-left: 10px;
                padding-right: 10px;
                min-width: 0;
                display: flex;
                
                align-items: center;
                line-height: normal;
            }
        }
    }

	.card-tabel-lista{
		margin-bottom:20px;
	}
	.card-principal{
		vertical-align: middle
	}
	.buton-adauga-tabel{
		position: absolute;
    	top: 50%;
	}
}

</style>