<template>
  <el-dialog title="Adauga variabila" :visible.sync="showPopup" append-to-body  :close-on-click-modal='false'>
    <el-button type="success" @click="showPopupAddVariable = true" icon="el-icon-plus" style="float: right"></el-button>
    <el-form label-position="top" @submit.prevent="save()">
      <el-form-item label="Variabile">
        <el-button 
          style="width: 100%; margin: 0" 
          v-for="(variable, index) in FilteredVariables" 
          :key="index" 
          @click="addVariable(variable.Nume, variable.Html)" 
          :type="ExistingVars.includes(variable) ? 'info' : ''">
          {{variable.Nume}}
        </el-button>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" >
      <el-button @click="showPopup = false">Anuleaza</el-button>
    </span>
    <el-dialog title="Adauga variabila" :visible.sync="showPopupAddVariable" append-to-body  :close-on-click-modal='false'>
      <el-form label-position="top" @submit.prevent="save()">
        <el-form-item label="Nume (este recomandat sa fie scris cu litere mici si despartit prin spatiu)">
          <el-input v-model="newVarKey"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" >
        <span style="float: left; color: red; font-weight: 200">{{ ErrorMessage }}</span>
        <el-button @click="adaugaVariabila" type="success">Adauga</el-button>
        <el-button @click="showPopupAddVariable = false">Anuleaza</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import BasePage from "@/pages/BasePage"
import { EventBus } from '../../main'
import $ from 'jquery'

export default {
  extends: BasePage,
  name: "AddVariable",
  data () { 
    return {
      Variables: [],
      Filter: '',
      newVarKey: '',
      Error: false,
      ErrorMessage: '',
      showPopupAddVariable: false,
      FilteredVariables: [],
      ExistingVars: [],
      focused_element: {},
      selectionInfo: {},
      parentnode: {},
      textnode: {},
      selection_start_index: 0,
      showPopup: false
    }
  },
  methods: {
    show () {      
      this.showPopup = true
    },
    addVariable(varName, html) {
      this.$nextTick(() => {
        $(".note-editable").focus()

        this.$nextTick(() => {
          $('#summernote').summernote('restoreRange')
          $("#summernote").summernote('pasteHTML', html)
        })
      })

      this.$emit('insertedVar',varName)
      console.log('inserted', varName);
      this.showPopup = false
    },
    async adaugaVariabila(){
      let key = this.generate_key(this.newVarKey);
      console.log(key);
      let res = await this.post('Formulare/addVariable', {
        key: key
      })

      this.Error = res.Error;
      this.ErrorMessage = res.ErrorMessage;

      if(this.Error == false){
        this.showPopupAddVariable = false;
        this.newVarKey = '';
        await this.refresh_info();
      }

      console.log(res);
    },

    remove_diacritica: function(string) {
        let data_to_replace = [
            {original: 'â', replace: 'a'}, 
            {original: 'ă', replace: 'a'},
            {original: 'î', replace: 'i'},
            {original: 'ș', replace: 's'},
            {original: 'ț', replace: 't'}
        ];

        data_to_replace.forEach(data => {
            string = string.replaceAll(data.original, data.replace);
        });

        return string;
    },

    generate_key: function(title = ''){
      let key = this.remove_diacritica(title)
                      .toLowerCase()
                      .replace(/\s+/g, '_')
                      .replace(/&/g, '-y-')
                      .replace(/[^\w\-]+/g, '')
                      .replace(/\-\-+/g, '-');  

      if(key.slice(-1) === '-')
          key = key.substring(0, key.length - 1);
      return key;
    },

    filterVariables() {
      if (!this.Filter || this.Filter.length == 0) {
        this.FilteredVariables = this.Variables
        return
      }

      this.FilteredVariables = this.Variables.filter((variable) => {
        return variable.Nume.toUpperCase().includes(this.Filter.toUpperCase())
      })
    },
    getExistingVars(){
        let ExistingVars = []
        $(".note-editor .variabila").each((index, value) => {
          var text = $.trim($(value).text())
          if (ExistingVars.indexOf(text) == -1 && text != "") {
            ExistingVars.push(text)
          }
        })
        this.ExistingVars = ExistingVars

        console.log('existing', this.ExistingVars);

        this.$emit('existingVarsReady', this.ExistingVars)
    },
    async refresh_info(){
      let response = await this.post("template_formulare/get_variabiles", {})
      this.Variables = response.Variables
      this.filterVariables()   
      this.getExistingVars(); 
    },
  },
  async mounted() {
    this.refresh_info();
  }

  // async mounted() {
  //   let response = await this.post("variabile/get_variabile", {})
  //   this.Variables = response.Variabile
  //   this.filterVariables()   
  //   this.getExistingVars(); 
  // }
}
</script>
<style lang="less" scoped>
</style>
