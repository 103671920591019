<template>
  <vue-editor ref="editor" v-model="content" :editorToolbar="editorToolbar" :editorOptions="editorOptions"></vue-editor>
</template>
<script>
import { VueEditor, Quill } from "vue2-editor";

export default {
  name: "FormularEditor",
  props: {
    model: {
      required: true
    },
    height: {
      type: String,
      default: '150'
    },
    contract:{
      type: Boolean,
      default: false
    }
  },
  watch: {
    model(val) {
      this.content = val
    }
  },
  data() {
    return {
      editorToolbar: [],
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              [{"header":[false,1,2,3,4,5,6]}],["bold","italic","underline","strike"],[{"align":""},{"align":"center"},{"align":"right"},{"align":"justify"}],["blockquote","code-block"],[{"list":"ordered"},{"list":"bullet"},{"list":"check"}],[{"indent":"-1"},{"indent":"+1"}],[{"color":[]},{"background":[]}],["link","image","video"],["clean"],['add']
            ],
            handlers: {
              'add': () => { console.log('customControl was clicked') }
            }
          }
        }
      },
      status: 0,
      innerChangeTimeout: null,
      content: null
    }
  },
  components: {
    VueEditor
  },
  mounted() {
    var newElement = document.createElement('button')
    //
    newElement.className = "variabila"
    $(newElement).text('asd')
    $(newElement).attr("type"           , 'button')
    $(newElement).attr("contenteditable", 'false')

    var span = document.createElement('span')
    span.appendChild(newElement)
    var editor = document.getElementsByClassName('ql-editor')
    editor[0].innerHTML = span.outerHTML
    this.content = span.outerHTML
  }
}
</script>

<style lang="less">
  .ql-add::after {
    content: "Adauga variabila";
  }

  .ql-add {
    width: 150px !important;
  }
</style>