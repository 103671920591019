<template>
  <el-dialog title="Editare variabila" :visible.sync="showPopup" append-to-body width="75%" :close-on-click-modal='false'>
    <el-form label-position="top" @submit.prevent="save()">
      <el-row :gutter="15">
        <el-col :md="8">
          <el-form-item label="Variabile existente">
            <div style="height: 400px; overflow: scroll">
              <div class="list-group">
                <a v-for="(v, index) in existingVars" :key="index" class="list-group-item" style="padding: 2px 5px" @click="varName=v">{{v}}</a>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-row :gutter="15">
            <el-col :md="24">
              <el-form-item label="Variabile de sistem">
                <el-button v-for="v in systemVars" :key="v" @click="varName=v">{{v}}</el-button>
              </el-form-item>
            </el-col>
            <el-col :md="24">
              <el-form-item label="Nume variabila">
                <input type="text" required class="form-control" v-model="varName"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form> <!-- /form -->
    <span slot="footer" class="dialog-footer" >
      <el-button @click="showPopup = false">Anuleaza</el-button>
      <el-button type="danger" @click="delete_var" v-if="mode=='edit'">Sterge</el-button>
      <el-button type="primary" @click="save">Salveaza</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { EventBus } from '../../main'
import $ from 'jquery'

export default {
  name: "BpVarEdit",
  data () { 
    return {
      variables: [],
      name: "",
      systemVars: ["contract_number", "contract_date"],
      existingVars: [],
      mode: "add",
      varName: "",
      domElement : {},
      summerNoteContext: {},
      focused_element: {},
      selectionInfo: {},
      parentnode: {},
      textnode: {},
      selection_start_index: 0,
      showPopup: false
    } 
  },
  methods: {
    show_me (mode, varName, domElement, summerNoteContext) {
      this.mode               = mode;
      this.varName            = varName;
      this.domElement         = domElement;
      this.summerNoteContext  = summerNoteContext;
      this.selectionInfo      = window.getSelection()
      this.focused_element    = window.getSelection().focusNode

      this.parentnode             = this.focused_element.parentNode
      this.textnode               = this.focused_element
      this.selection_start_index  = this.selectionInfo.baseOffset

      var existingVars = []

      $(".note-editor .variabila").each((index, value) => {
        var text = $.trim($(value).text())
        if (existingVars.indexOf(text) == -1 && text != "") {
          existingVars.push(text)
        }
      })
      this.existingVars = existingVars

      this.showPopup = true
    },
    save () {        
      this.showPopup = false

      if (this.mode == "edit") {
        $(this.domElement).text(this.varName)
      } else {
        var newElement        = document.createElement('button')
        newElement.className  = "variabila"
        $(newElement).text(this.varName)
        $(newElement).attr("type"           , 'button')
        $(newElement).attr("contenteditable", 'false')

        var span = document.createElement('span')
        span.appendChild(newElement)

        $("#summernote").summernote('focus')
        $("#summernote").summernote('insertNode', span)

        this.add_contract_field_button_actions()
      }
    },
    delete_var () {
      this.$confirm('Sunteti sigur', 'Warning', {type: 'warning'})
        .then(() => {
          this.LoadingVisible = true
          $(this.domElement).remove()
          this.showPopup = false
        })
    }
  },
  created () {
    var me = this
    this.name = "var_edit_dialog_" + new Date().getTime()
    EventBus.$on('show_var_edit_dialog', function(mode, varName, domElement, summerNoteContext) {
      me.show_me(mode, varName, domElement, summerNoteContext)
    })
  }
}
</script>